<template>
    <v-dialog transition="dialog-bottom-transition" v-model="show" v-on:click:outside="cancelMethod">
        <v-card>
            <v-toolbar color="darken-3" dark class="text-h5">Status Mensile Orari
                <v-spacer></v-spacer>{{ selectedMonth.setLocale('it').toFormat('MMMM yyyy') }}
                <v-spacer></v-spacer>
                {{ username }}
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelMethod">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text style="overflow-y: auto;">
                <v-container fluid v-if="!monthPreviewLoading">
                    <v-row v-for="(d, index) in displayMonthData || []" :key="d.date.ts"
                        :style="{ 'background-color': index % 2 == 0 ? '#EEEEEE' : '' }">
                        <v-col class="text-left font-weight-medium">
                            {{ d.date.setLocale('it').toFormat('ccc dd') }}
                        </v-col>
                        <v-col class="">
                            <div class="font-weight-medium d-flex flex-column mx-2 justify-center"
                                v-if="(d.daySchedule && (d.daySchedule.morningTimes || d.daySchedule.afternoonTimes))">
                                <span v-if="d.daySchedule.morningTimes">{{ displayClockTime(d.daySchedule.morningTimes)
                                }}</span>
                                <span v-if="d.daySchedule.afternoonTimes">{{ displayClockTime(d.daySchedule.afternoonTimes)
                                }}</span>
                            </div>
                        </v-col>
                        <v-col cols="7" class="d-flex flex-row align-center" style="overflow-x: auto; white-space: nowrap;">
                            <span v-for="(clock, index) in d.clocks" :key="index + '_clock'" class="d-flex flex-row">

                                <v-sheet outlined :color="clock.lateEnter ? 'orange lighten-4' : 'grey lighten-2'"
                                    class="px-1 rounded-l-pill">

                                    <span class="font-weight-medium">
                                        {{ clock.in }}
                                    </span>


                                </v-sheet>

                                <v-sheet outlined :color="clock.earlyLeave ? 'orange lighten-4' : 'grey lighten-2'"
                                    class="px-1 mr-2 rounded-r-pill">

                                    <span class="font-weight-medium">
                                        {{ clock.out }}
                                    </span>


                                </v-sheet>
                            </span>
                            <!-- {{ d.clocks }} -->
                        </v-col>
                        <v-col class="d-flex justify-end" style="overflow-x: auto; white-space: nowrap;">

                            <v-sheet color="cyan accent-3" rounded="circle" class="d-flex justify-center align-center"
                                height="20" width="20" v-if="d.holiday">
                                <v-icon color="black">
                                    mdi-alpha-f
                                </v-icon>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="20" width="20"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-f
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="teal accent-3" rounded="pill"
                                class="d-flex flex-row justify-space-between align-center px-2" height="20"
                                v-if="d.leaveMinutes > 0">
                                <v-icon left color="black">
                                    mdi-alpha-p
                                </v-icon>
                                <v-spacer />
                                <span class="font-weight-medium mr-2">
                                    {{ displayDuration(d.leaveMinutes) }}
                                </span>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="20" width="20"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-p
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="purple lighten-3" rounded="pill"
                                class="d-flex flex-row justify-space-between align-center px-2" height="20"
                                v-if="d.overtimeMinutes > 0">
                                <v-icon left color="black">
                                    mdi-alpha-s
                                </v-icon>
                                <v-spacer />
                                <span class="font-weight-medium mr-2">
                                    {{ displayDuration(d.overtimeMinutes) }}
                                </span>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="20" width="20"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-s
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="amber accent-4" rounded="circle" class="d-flex justify-center align-center"
                                height="20" width="20" v-if="d.businessTrip">
                                <v-icon color="black">
                                    mdi-alpha-t
                                </v-icon>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="20" width="20"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-t
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="deep-orange lighten-3" rounded="circle"
                                class="d-flex justify-center align-center" height="20" width="20" v-if="d.availability">
                                <v-icon color="black">
                                    mdi-alpha-r
                                </v-icon>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="20" width="20"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-r
                                </v-icon>
                            </v-sheet>

                            <span class="mx-1" />

                            <v-sheet color="pink lighten-3" rounded="circle" class="d-flex justify-center align-center"
                                height="20" width="20" v-if="d.sick">
                                <v-icon color="black">
                                    mdi-alpha-m
                                </v-icon>
                            </v-sheet>
                            <v-sheet rounded="circle" class="d-flex justify-center align-center" height="20" width="20"
                                v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alpha-m
                                </v-icon>
                            </v-sheet>

                        </v-col>
                    </v-row>

                    <!-- <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                    </th>
                                    <th class="text-center">
                                        TIMBRATURE
                                    </th>
                                    <th class="text-center">
                                        MINUTI TIMBRATI
                                    </th>
                                    <th class="text-center">
                                        MINUTI PERMESSO
                                    </th>
                                    <th class="text-center">
                                        FERIE
                                    </th>
                                    <th class="text-center">
                                        MINUTI STRAORDINARIO
                                    </th>
                                    <th class="text-center">
                                        TRASFERTA
                                    </th>
                                    <th class="text-center">
                                        MALATTIA
                                    </th>
                                    <th class="text-center">
                                        TURNO
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="d in displayMonthData"
                                    :key="d.date.ts"
                                >
                                    <td class="text-right">
                                        {{d.date.setLocale('it').toFormat('cccc dd')}}
                                    </td>
                                    <td class="text-center d-flex flex-column">
                                        <div v-for="(clock, index) in d.clocks" :key="index">
                                            <span>{{ clock.in }}</span> -  <span>{{ clock.out }}</span>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        {{ d.workedMinutes }}
                                    </td>
                                    <td class="text-center">
                                        {{ d.leaveMinutes > 0 ? d.leaveMinutes : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{ d.holiday ? 'F' : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{ d.overtimeMinutes > 0 ? d.overtimeMinutes : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{ d.businessTrip ? 'T' : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{ d.sick ? 'M' : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{ d.availability ? 'R' : ''}}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table> -->
                </v-container>
                <v-progress-linear v-else indeterminate></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { DateTime } from 'luxon';
import { mapActions } from 'vuex'
import userWorkDayApi from "../../../api/userWorkDay";
import userWorkScheduleApi from '../../../api/userWorkSchedule';
import * as snackbarActions from '../../../store/actions/snackbar';

export default {
    data: function () {
        return {
            monthPreviewLoading: false,
            monthData: null,
        }
    },
    props: {
        // userWorkingHoursData: {
        //     type: Object,
        //     default: function() {
        //         return {};
        //     }
        // },
        // monthPreviewLoading: {
        //     type: Boolean,
        //     default: false
        // },
        username: {
            type: String,
            // required: true
        },
        show: {
            type: Boolean,
            required: true
        },
        selectedMonth: {
            type: Object,
            // required: true
        }
    },
    methods: {
        ...mapActions({
            showSnackbar: snackbarActions.SNACKBAR_SHOW
        }),
        displayClockTime: function (clockTime) {
            const cIn = clockTime.clockInTime ? DateTime.fromISO(clockTime.clockInTime).toFormat("HH:mm") : "";
            const cOut = clockTime.clockOutTime ? DateTime.fromISO(clockTime.clockOutTime).toFormat("HH:mm") : "";
            return `${cIn} - ${cOut}`;
        },
        displayDuration: function (minutes) {
            const hours = Math.floor(minutes / 60);
            const min = minutes % 60;
            if (hours > 0) {
                if (min > 0) {
                    return `${hours}h ${min}m`;
                } else {
                    return `${hours}h`;
                }
            } else {
                return `${min}m`;
            }
        },
        cancelMethod: function () {
            this.$emit('close-monthly-user-status-dialog');
        },
        getDateOfMonth: function (day) {
            return DateTime.fromObject({ day: day, month: this.selectedMonth.get("month"), year: this.selectedMonth.get("year") });
        },
        // findWorkDateData: function(day) {
        //     console.log()
        //     return this.userWorkingHoursData.workingHoursDaysData.find(d => DateTime.fromISO(d.workDate).toISODate() == this.getDateOfMonth(day).toISODate());
        // }
        getDateWorkingTimes: function (schedule, date) {
            switch (date.getDay()) {
                case 1:
                    return schedule.scheduledWeekWorkTimes.mondayWorkTimes;
                case 2:
                    return schedule.scheduledWeekWorkTimes.tuesdayWorkTimes;
                case 3:
                    return schedule.scheduledWeekWorkTimes.wednesdayWorkTimes;
                case 4:
                    return schedule.scheduledWeekWorkTimes.thursdayWorkTimes;
                case 5:
                    return schedule.scheduledWeekWorkTimes.fridayWorkTimes;
                case 6:
                    return schedule.scheduledWeekWorkTimes.saturdayWorkTimes;
                case 0:
                    return schedule.scheduledWeekWorkTimes.sundayWorkTimes;
                default: return {};
            }
        },
        createClockObjects: function (cRecords, scheduledTimes, date) {
            let morningClockInTime = null;
            let morningClockOutTime = null;
            const objectDate = {
                day: date.day,
                month: date.month,
                year: date.year,
            }
            if (scheduledTimes && scheduledTimes.morningTimes.clockInTime && scheduledTimes.morningTimes.clockOutTime) {
                morningClockInTime = DateTime.fromISO(scheduledTimes.morningTimes.clockInTime).set(objectDate);
                morningClockOutTime = DateTime.fromISO(scheduledTimes.morningTimes.clockOutTime).set(objectDate);
            }

            let afternoonClockInTime = null;
            let afternoonClockOutTime = null;
            if (scheduledTimes && scheduledTimes.afternoonTimes.clockInTime && scheduledTimes.afternoonTimes.clockOutTime) {
                afternoonClockInTime = DateTime.fromISO(scheduledTimes.afternoonTimes.clockInTime).set(objectDate);
                afternoonClockOutTime = DateTime.fromISO(scheduledTimes.afternoonTimes.clockOutTime).set(objectDate);
            }

            let objectes = cRecords.map(record => {
                let lateEnter = false;
                let earlyLeave = false;

                let punchInTime = DateTime.fromISO(record.punchInTime);
                let punchOutTime = record.punchOutTime ? DateTime.fromISO(record.punchOutTime) : null;

                // console.log(punchInTime.toLocaleString(), punchOutTime.toLocaleString(), morningClockInTime.toLocaleString(), morningClockOutTime.toLocaleString(), afternoonClockInTime.toLocaleString(), afternoonClockOutTime.toLocaleString())

                if (morningClockInTime && morningClockOutTime) {
                    if (
                        (!afternoonClockInTime || !afternoonClockOutTime || punchInTime < morningClockOutTime) && punchInTime > morningClockInTime.plus({ minutes: 1 })
                    ) {
                        lateEnter = true;
                    }

                    if (punchOutTime > morningClockInTime && punchOutTime < morningClockOutTime) {
                        earlyLeave = true;
                    }
                }

                if (afternoonClockInTime && afternoonClockOutTime) {
                    if (
                        punchInTime < afternoonClockOutTime && punchInTime > afternoonClockInTime.plus({ minutes: 1 })
                    ) {
                        lateEnter = true;
                    }

                    if (punchOutTime > afternoonClockInTime && punchOutTime < afternoonClockOutTime) {
                        earlyLeave = true;
                    }
                }

                return {
                    in: punchInTime ? DateTime.fromISO(punchInTime).setLocale("it").toFormat("HH:mm") : "-:-",
                    out: punchOutTime ? DateTime.fromISO(punchOutTime).setLocale("it").toFormat("HH:mm") : "-:-",
                    earlyLeave: earlyLeave,
                    lateEnter: lateEnter
                }

            });
            return objectes;
        },
        fetchData: async function () {
            const schedulesResponse = (await userWorkScheduleApi.getUserWorkSchedulesByUser(this.username));
            this.monthPreviewLoading = true;
            let response = await userWorkDayApi.getUserWorkDayByUserDateRange(this.username, this.startOfMonth.toISODate(), this.endOfMonth.toISODate());
            if (response.success && schedulesResponse.success) {
                const schedules = schedulesResponse.data;
                this.monthData = response.data.map(d => {
                    const date = DateTime.fromISO(d.workDate).setLocale("it");
                    const currentSchedule = schedules.find((s) => {
                        const start = DateTime.fromISO(s.validityStartDate);
                        const stop = s.validityStopDate ? DateTime.fromISO(s.validityStopDate) : null;
                        return (date >= start && (stop == null || date < stop));
                    });
                    const daySchedule = currentSchedule ? this.getDateWorkingTimes(currentSchedule, date.toJSDate()) : {};
                    return {
                        date: DateTime.fromISO(d.workDate).startOf('day'),
                        businessTrip: d.businessTrip,
                        overtimeMinutes: d.overtimeMinutes,
                        // records: d.workDayRecords,
                        clocks: this.createClockObjects(d.workDayRecords.filter(w => w.recordType == "CLOCK_RECORD").map(r => r.clockRecord), daySchedule, date.toObject()),
                        // clocks: d.workDayRecords.filter(w => w.recordType == "CLOCK_RECORD").map(r => {
                        //     return {
                        //         in: r.clockRecord.punchInTime ? DateTime.fromISO(r.clockRecord.punchInTime).setLocale("it").toFormat("HH:mm") : "-:-",
                        //         out: r.clockRecord.punchOutTime ? DateTime.fromISO(r.clockRecord.punchOutTime).setLocale("it").toFormat("HH:mm") : "-:-",
                        //     }
                        // }),
                        daySchedule: daySchedule,
                        workedMinutes: '-',
                        leaveMinutes: d.workDayRecords.filter(w => w.recordType == "LEAVE_RECORD").map(w => w.leaveRecord.minuteDuration).reduce((partialSum, a) => partialSum + a, 0),
                        holiday: d.workDayRecords.some(w => w.recordType == "HOLIDAY_RECORD"),
                        sick: d.workDayRecords.some(w => w.recordType == "SICK_RECORD"),
                        availability: d.workDayRecords.some(w => w.recordType == "AVAILABILITY_RECORD"),
                    }
                });
            } else {
                this.showSnackbar({ text: "ERRORE NEL CARICAMENTO DEI DATI DELL'UTENTE " + this.username, type: "ERROR" });
            }
            this.monthPreviewLoading = false;
        }
    },
    computed: {
        startOfMonth: function () {
            return this.selectedMonth ? this.selectedMonth.startOf('month') : null;
        },
        endOfMonth: function () {
            return this.selectedMonth ? this.selectedMonth.endOf('month') : null;
        },
        displayMonthData: function () {
            let cursor = this.startOfMonth;
            const data = [];
            while (cursor < this.endOfMonth) {
                const foundWD = (this.monthData || []).find(d => d.date.startOf("day").ts == cursor.startOf("day").ts);
                data.push(foundWD || { date: cursor.startOf("day") })
                cursor = cursor.plus({ days: 1 });
            }
            return data;
        },
    },
    watch: {
        show: async function (val) {
            if (val) {
                await this.fetchData();
            }
        },
    },
}
</script>
<style scoped>
.scroller {
    overflow-y: auto;
    overflow-x: hidden;
}
</style>